import React, { useEffect, useState } from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import FAQsContent from '../../components/FAQs/FAQs'
import { Faq } from '../../components/FAQs/faqs-list-interface'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import {
  FAQS,
  FAQS_PAYMENTS_WITHDRAWALS,
} from '../../components/internal-links'
import {
  generateBreadcrumbsSchema,
  generateFaqSchema,
  generateOrgSchema,
  generateWebsiteSchema,
} from '../../lib/generate-schema'
import {
  pokerOneLinksStaticPage,
  rummyOneLinksStaticPage,
} from '../../components/one-links'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'FAQs',
    url: FAQS,
  },
  {
    title: 'Payments and Withdrawls',
    url: FAQS_PAYMENTS_WITHDRAWALS,
  },
]

const FAQs: React.FC = () => {
  const [kycPaymentWithdrawalsFaqsSt, setKycPaymentWithdrawalsFaqsSt] =
    useState<Faq[]>([])

  useEffect(() => {
    import('../../components/FAQs/faqs-list').then(
      ({ kycPaymentWithdrawalsFaqs }) =>
        setKycPaymentWithdrawalsFaqsSt(kycPaymentWithdrawalsFaqs)
    )
  }, [])

  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      desktopBreadcrumbColor="black"
      pokerOneLink={pokerOneLinksStaticPage}
      rummyOneLink={rummyOneLinksStaticPage}
    >
      <SEO
        title="FAQs: Payment, Withdrawal, Transaction, And KYC | Mega"
        description="Read frequently asked questions on payments and withdrawl in Mega. Find answers to queries on how to deposit, wallets, withdrawl time among others. Click to read more!"
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
        faqSchema={generateFaqSchema(kycPaymentWithdrawalsFaqsSt)}
      />
      <FAQsContent
        title="Payments And Withdrawals"
        index={5}
        faqs={kycPaymentWithdrawalsFaqsSt}
      />
    </Layout>
  )
}

export default FAQs
